import React from "react";
import Card from "react-bootstrap/Card";
import { GiWeightLiftingUp, GiGuitarHead } from "react-icons/gi";
import { MdOutlineSportsCricket } from "react-icons/md";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hey, I'm <span className="purple">Ryan! </span>
            I grew up in <span className="purple"> New Jersey </span>
            and recently graduated from <span className="purple"> Ursinus College </span>
            with a Bachelor's degree in
            <span className="purple"> Computer Science. </span>
            {/* <br /> */}
            I am currently employed for a startup company working as a <span className="purple"> Full Stack Developer.</span>
            <br />
            <br />
            Outside of coding, some of my hobbies include:
          </p>
          <ul>
            <li className="about-activity">
              <MdOutlineSportsCricket /> Coaching lacrosse
            </li>
            <li className="about-activity">
              <GiGuitarHead /> Playing guitar
            </li>
            <li className="about-activity">
              <GiWeightLiftingUp /> Weightlifting
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
