import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import MouseTracker from '../MouseTracker';
import stack from "../../Assets/Projects/full.png";
import shazam from "../../Assets/Projects/shaz.png";
import penguin from "../../Assets/Projects/penguin.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <MouseTracker />
      <Container>
        <h1 className="project-heading">
          Some notable <strong className="purple">Work </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={shazam}
              isBlog={false}
              title="Shazam simulator"
              description="Used Object-Oriented programming design principles to implement an algorithm that detects and returns appropriate songtitles from an audio input"
              ghLink="https://github.com/ryfitz/Shazamsim"
            // demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={penguin}
              isBlog={false}
              title="Traveling Salesperson Art"
              description="Implemented depth first search and approximated traveling salesperson solutions using minimum spanning trees and the 2-opt heuristic to create art programmatically"
              ghLink="https://github.com/ryfitz/TSPArt"
            // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={stack}
              isBlog={false}
              title="Full-Stack Web application"
              description="Worked collaboratively to contribute to a website built to help students plan their college courses, React and MongoDb."
              ghLink="https://github.com/ryfitz"
            // demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
