import React, { useState, useEffect } from 'react';

const MouseTracker = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    setPosition({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div>
      <div
        className="circle"
        style={{
          left: position.x - 10, // Adjusting to keep the circle centered
          top: position.y - 10,
          position: 'fixed', // Ensures the circle stays in the viewport
        }}
      ></div>
    </div>
  );
};

export default MouseTracker;


