import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BsLinkedin } from "react-icons/bs";
import { Carousel, Row, Col } from "react-bootstrap";

function WorkExperience() {
  const experiences = [
    {
      title: "Full-Stack Developer",
      company: "Scientia",
      duration: "May 2024 - Present",
      description: "Lead Developer building full-stack web application intended to optimize the consulting process for companies.",
      linkedInLink: "https://www.linkedin.com/in/ryanwfitz",
    },
    {
      title: "SQL Database Manager - Intern",
      company: "LevelUp",
      duration: "May 2023 - September 2023",
      description: "Designed and implemented a robust SQL pipeline using SQL Server and SSIS, ensuring data integrity and seamless system integration.",
      linkedInLink: "https://www.linkedin.com/company/leveluphcs/posts/?feedView=all",
    },
    {
      title: "Computer Science Graduate",
      company: "Ursinus College",
      duration: "August 2020 - May 2024",
      description: "Earned Bachelor's Degree in Computer Science at top ranked school, Ursinus College.",
      linkedInLink: "https://www.linkedin.com/school/ursinus-college/posts/?feedView=all",
    },
    {
      title: "Software Engineer",
      company: "Your Company!",
      duration: "Soon!",
      description: "I would love to work for YOUR company!",
      linkedInLink: "https://www.linkedin.com/in/ryanwfitz",
    },
    // Add more experiences as needed
  ];

  // Helper function to group experiences into sets of 3
  const groupExperiences = (items, groupSize) => {
    let groups = [];
    for (let i = 0; i < items.length; i += groupSize) {
      groups.push(items.slice(i, i + groupSize));
    }
    return groups;
  };

  const experienceGroups = groupExperiences(experiences, 3);

  return (
    <Carousel interval={null}>
      {experienceGroups.map((group, index) => (
        <Carousel.Item key={index}>
          <Row>
            {group.map((experience, idx) => (
              <Col key={idx} className="mb-4">
                <Card className="work-card-view">
                  <Card.Body>
                    <Card.Title>{experience.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      {experience.company} | {experience.duration}
                    </Card.Subtitle>
                    <Card.Text>{experience.description}</Card.Text>
                    <Button
                      variant="primary"
                      href={experience.linkedInLink}
                      target="_blank"
                    >
                      <BsLinkedin /> &nbsp; LinkedIn
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default WorkExperience;
