import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.png";
import homeLogoOff from "../../Assets/home-main-off.png";
import Particle from "../Particle";
import MouseTracker from '../MouseTracker';
import Home2 from "./Home2";
import Type from "./Type";

function Home({ isToggleOn }) {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <MouseTracker />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hey there!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  🤝
                </span>
              </h1>

              <h1 className="heading-name">
                I'm
                <strong className="main-name"> Ryan Fitzgerald</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col
              md={5}
              style={{
                paddingBottom: 20,
                paddingLeft: 0,
                marginLeft: '-75px',
                marginTop: '-30px',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                  src={homeLogoOff}
                  alt="home pic off"
                  className="img-fluid"
                  style={{ maxHeight: '250px', marginBottom: '0px' }} // Smaller and with margin below
                />
                {isToggleOn && (
                  <img
                    src={homeLogo}
                    alt="home pic"
                    className="img-fluid"
                    style={{ maxHeight: '350px' }} // Smaller and directly beneath
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;







